export interface SidebarState {
    menu?: any[];
    sidebarStatus?: boolean;
}

export interface SetSidebarAction {
    type: 'SET_SIDEBAR';
    sidebar: any[];
}

export interface OpenSidebarAction {
    type: 'OPEN_SIDEBAR';
    status: boolean;
}

export type SidebarActionTypes = SetSidebarAction | OpenSidebarAction;

// Define the initial state
const initialState: SidebarState = {
    menu: [],
    sidebarStatus: false,
};

// Define the reducer
const sidebarReducer = (state: SidebarState = initialState, action: SidebarActionTypes): SidebarState => {
    switch (action.type) {
        case 'SET_SIDEBAR':
            return { ...state, menu: action.sidebar };
        case 'OPEN_SIDEBAR':
            return { ...state, sidebarStatus: action.status };
        default:
            return state;
    }
};

export default sidebarReducer;
