import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { isMobile, isTablet, isIOS, isAndroid } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';

const AppBanner = () => {
    const [open, setOpen] = useState(false);
    const [isClient, setIsClient] = useState(false);

    const getCookie = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('app='))
            ?.split('=')[1];
        return cookieValue;
    }

    const goToLink = () => {
        setCookie();
        const link = isIOS
            ? 'https://apps.apple.com/us/app/booksport/id1585479595'
            : 'https://play.google.com/store/apps/details?id=ro.Booksport.Booksport';
        window.location.href = link;
    }

    const setCookie = () => {
        document.cookie = `app=true;max-age=604800;Secure;path=/`;
    }

    const closeBanner = () => {
        setOpen(false);
        setCookie();
    }

    useEffect(() => {
        setIsClient(true); // This ensures the component is client-side
        const hasCookie = getCookie();
        if (!hasCookie && (isMobile || isTablet)) {
            setTimeout(() => {
                setOpen(true);
            }, 2000);
        }
    }, []);

    if (!isClient || (!isMobile && !isTablet)) {
        return null; // Ensure the banner doesn't render on the server or on desktop devices
    }

    return (
        <div className={open ? 'app-banner open' : 'app-banner'}>
            <CloseIcon style={{ fontSize: 22 }} onClick={closeBanner} />
            <Image
                src='/images/logo-small.svg'
                alt="app logo"
                width={30}
                height={50}
                className="img-fluid mr-2 ml-2"
            />
            <div className="app-description">
                <p className="title-app">Booksport</p>
                <p>Descarca aplicatia</p>
            </div>
            <button onClick={goToLink}>Descarca</button>
        </div>
    );
}

export default AppBanner;
