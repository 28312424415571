// utils/base64Utils.ts
export const decodeBase64 = (base64String: string) => {
    try {
        const decodedString = Buffer.from(base64String, 'base64').toString('utf-8');
        return JSON.parse(decodedString);
    } catch (error) {
        console.error('Failed to decode base64 string:', error);
        return null;
    }
};
