import React, { useRef } from 'react';
// @ts-ignore
import Slider, { Settings } from 'react-slick';
import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    IconButton,
    Rating,
} from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarIcon from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import Link from 'next/link';
import Image from 'next/image';

// Define the shape of a Club object based on the properties used
interface Club {
    id: string | number;
    slug: string;
    main_card_message?: string;
    main_card_message_bkg_color?: string;
    main_card_message_color?: string;
    filename?: string;
    name: string;
    locationname: string;
    sport: string;
    booking: number;
    review: number;
    nr_review: number;
    address: string;
    elements: string;
    localitate: string;
}

// Define props for PromoClubsCrousel
interface PromoClubsCrouselProps {
    clubs: Club[];
    error?: string;
}

// Define the props for the Arrow component
interface ArrowProps {
    onClick?: () => void;
    direction: 'left' | 'right';
}

// Arrow Component with TypeScript
const Arrow: React.FC<ArrowProps> = ({ onClick, direction }) => {
    // Handle mouse events to change the SVG fill color
    const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const target = e.currentTarget.querySelector('svg');
        if (target) {
            target.style.fill = 'black';
        }
    };

    const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const target = e.currentTarget.querySelector('svg');
        if (target) {
            target.style.fill = 'darkgrey';
        }
    };

    return (
        <IconButton
            onClick={onClick}
            style={{
                backgroundColor: '#f5f5f5',
                borderRadius: '50%',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: direction === 'left' ? '16px' : '0',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <svg
                viewBox="0 0 10 16"
                width="12"
                height="12"
                style={{ fill: 'darkgrey' }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d={
                        direction === 'left'
                            ? 'M4.511 8L10 13.704 7.398 16 0 8l7.398-8L10 2.296z'
                            : 'M5.489 8L0 2.296 2.602 0 10 8l-7.398 8L0 13.704z'
                    }
                />
            </svg>
        </IconButton>
    );
};

const PromoClubsCrousel: React.FC<PromoClubsCrouselProps> = ({ clubs, error }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const carouselRef = useRef<Slider>(null);

    // Slider settings with TypeScript typings
    const settings: Settings = {
        dots: isMobile,
        dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        autoplay: true,
        autoplaySpeed: 5000,
    };

    if (error) {
        return (
            <Box className="container mx-auto mt-16 lg:mt-24 promo-clubs" position="relative">
                <Typography
                    variant="h3"
                    sx={{
                        color: '#12803c',
                        fontWeight: 500,
                        fontSize: { xs: '22px', md: '34px' },
                        margin: '20px 0',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                >
                    Cluburi exclusive
                </Typography>
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Box className="container mx-auto mt-16 lg:mt-24 promo-clubs" position="relative">
            <Typography
                variant="h3"
                sx={{
                    color: '#12803c',
                    fontWeight: 500,
                    fontSize: { xs: '22px', md: '34px' },
                    margin: '20px 0',
                    fontFamily: 'Poppins, sans-serif',
                }}
            >
                Cluburi exclusive
            </Typography>
            {clubs && clubs.length > 0 ? (
                <Slider ref={carouselRef} {...settings}>
                    {clubs.map((club) => (
                        <div key={club.id} className="club-card mb-3 pl-2 pr-2">
                            <div
                                className={
                                    club.booking === 0
                                        ? 'card card-blog mb-0 opacity-50'
                                        : 'card card-blog mb-0'
                                }
                            >
                                <Link href={`/${club.slug}`} passHref>
                                    <div className="card-header card-header-image relative">
                                        {club.main_card_message && (
                                            <div
                                                className="card-note"
                                                style={{
                                                    backgroundColor:
                                                        club.main_card_message_bkg_color || '#000',
                                                    color:
                                                        club.main_card_message_color || '#fff',
                                                }}
                                            >
                                                {club.main_card_message}
                                            </div>
                                        )}
                                        <div className="relative w-full h-64">
                                            {/* Next.js Image Component with updated props */}
                                            <Image
                                                src={
                                                    club.filename
                                                        ? `https://img.booksport.ro/clubs/${club.id}/small/${club.filename}`
                                                        : '/images/cards.jpg'
                                                }
                                                alt={club.name}
                                                fill
                                                style={{ objectFit: 'cover' }}
                                                priority
                                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                            />
                                            <Box className="card-title absolute bottom-4 left-4 text-white">
                                                {club.name} <br />
                                                {club.locationname}
                                            </Box>
                                            <Box className="sport absolute bottom-4 right-4 text-white px-3 py-1 rounded-full bg-light-orange">
                                                {club.sport}
                                            </Box>
                                            <div className="colored-shadow"> </div>
                                        </div>
                                    </div>
                                </Link>

                                <div className="card-body p-4">
                                    {club.review !== 0 ? (
                                        <div className="flex items-center">
                                            <Rating
                                                value={club.review}
                                                readOnly
                                                precision={0.5}
                                                icon={
                                                    <StarIcon
                                                        style={{ fontSize: '25px', color: '#faaf00' }}
                                                    />
                                                }
                                                emptyIcon={
                                                    <StarBorder
                                                        style={{ fontSize: '25px', color: '#faaf00' }}
                                                    />
                                                }
                                            />
                                            <span
                                                className="ml-2"
                                                style={{
                                                    fontStyle: 'normal',
                                                    color: '#000',
                                                    marginTop: 3,
                                                }}
                                            >
                                                ({club.nr_review}{' '}
                                                {club.nr_review > 1 ? 'review-uri' : 'review'})
                                            </span>
                                        </div>
                                    ) : (
                                        <div style={{ minHeight: '27px' }}></div>
                                    )}
                                    <h5
                                        className="card-category mb-2 text-gray-600"
                                        style={{ minHeight: '50px' }}
                                    >
                                        <span style={{ marginRight: '10px' }}>{club.address}</span>
                                        <span
                                            className="green"
                                            style={{ backgroundColor: '#651716', padding: '2px 6px', borderRadius: '4px' }}
                                        >
                                            {club.elements}
                                        </span>
                                    </h5>
                                    <Box className="font-semibold text-lg uppercase text-light-orange">
                                        {club.localitate}
                                    </Box>
                                </div>
                                <div className="card-footer text-center mb-3">
                                    <Link href={`/${club.slug}`} passHref legacyBehavior>
                                        <a
                                            className="text-right btn-more w-full mt-2 mb-2 text-center"
                                            style={{ margin: '20px 5px' }}
                                        >
                                            Vezi disponibilități
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                <Typography variant="body1">No clubs available.</Typography>
            )}
            {!isMobile && clubs && clubs.length > 0 && (
                <Box className="flex justify-center mt-4">
                    <Arrow direction="left" onClick={() => carouselRef.current?.slickPrev()} />
                    <Arrow direction="right" onClick={() => carouselRef.current?.slickNext()} />
                </Box>
            )}
        </Box>
    );
};

export default PromoClubsCrousel;
