'use client'
import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Box, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import { PulseLoader } from 'react-spinners';
import queryString from 'query-string';
import {useApi} from "@/hooks/useApi";

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const LocationFilter: React.FC = () => {
    const [locationsOptions, setLocationsOptions] = useState<{ label: string; value: string }[]>([]);
    const [locationValue, setLocationValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const router = useRouter();
    const { pathname } = router;
    const currentParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
    const booksport = useApi()

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const res = await booksport.get('/get_cities');
                const cities = res.data.map((city: { name: string; id: string }) => ({ label: city.name, value: city.id }));

                // Get user's current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        const currentLocationValue = `@${latitude},${longitude}`;
                        const currentLocation = { label: 'Locatia curenta', value: currentLocationValue };
                        const allLocations = [currentLocation, ...cities];
                        setLocationsOptions(allLocations);

                        // Get the location value from query params
                        const queryParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
                        const locationValueFromQuery = queryParams.location as string;

                        if (locationValueFromQuery && locationValueFromQuery.startsWith('@')) {
                            setLocationValue(currentLocationValue);
                        } else if (locationValueFromQuery) {
                            setLocationValue(locationValueFromQuery);
                        } else {
                            const defaultLocation = allLocations[0]?.value;
                            setLocationValue(defaultLocation);
                            queryParams.location = defaultLocation;
                            router.replace(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
                        }

                        setLoading(false);
                    },
                    (err) => {
                        console.error(err);
                        setLocationsOptions(cities);

                        // Get the location value from query params
                        const queryParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
                        const locationValueFromQuery = queryParams.location as string;

                        if (locationValueFromQuery) {
                            setLocationValue(locationValueFromQuery);
                        } else {
                            const defaultLocation = cities[0]?.value;
                            setLocationValue(defaultLocation);
                            queryParams.location = defaultLocation;
                            router.replace(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
                        }

                        setLoading(false);
                    }
                );
            } catch (err: any) {
                console.error(err.response);
                setLoading(false);
            }
        };

        fetchCities();
    }, []);

    useEffect(() => {
        if (currentParams.location?.toString().startsWith('@')) {
            if (!currentParams.radius) {
                currentParams.radius = '10';
            }
        } else {
            delete currentParams.radius;
        }
        const newQueryString = queryString.stringify(currentParams);
        router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
    }, [router.pathname]);

    const handleLocationChange = (event: any) => {
        const newLocation = event.target.value;

        currentParams.location = newLocation;

        if (newLocation.toString().startsWith('@')) {
            if (!currentParams.radius) {
                currentParams.radius = '10';
            }
        } else {
            delete currentParams.radius;
        }

        const newQueryString = queryString.stringify(currentParams);
        router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
        setLocationValue(newLocation);
    };


    return (
        <FormControl fullWidth>
            <GreenSelect
                value={locationValue}
                onChange={handleLocationChange}
                displayEmpty
                sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                { loading ? (
                    <MenuItem disabled value="" sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                        Se încarcă locațiile...
                    </MenuItem>
                ) : ( locationsOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                        {option.label}
                    </MenuItem> ))
                )}
            </GreenSelect>
        </FormControl>
    );
};

export default LocationFilter;
