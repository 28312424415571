import React from 'react';
import { useDispatch } from 'react-redux';
import { openSearchPanel } from '@/store/actions/search';
import Box from '@mui/material/Box';
import Image from 'next/image';
import SearchForm from "@/components/search/SearchForm";

interface OpenSearchProps {
    white?: boolean;
}

const OpenSearch: React.FC<OpenSearchProps> = ({ white }) => {
    const dispatch = useDispatch();

    const SearchIcon = '/images/search-icon.svg';
    const SearchIconWhite = '/images/search-icon-white.svg';

    const openSearch = () => {
        dispatch(openSearchPanel());
    };

    return (
        <>
            <Box
                onClick={openSearch}
                sx={{
                    cursor: 'pointer'
                }}
            >
                <Image className='search-button' src={white ? SearchIconWhite : SearchIcon} alt="Search" height={22} width={22} />
            </Box>
            <SearchForm/>
        </>
    );
};

export default OpenSearch;
