interface AuthState {
    isAuth: boolean;
    user: { [key: string]: any };
}

const initialState: AuthState = {
    isAuth: false,
    user: {},
};

const authReducer = (state = initialState, action: any): AuthState => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            return {
                isAuth: Object.keys(action.user).length > 0,
                user: action.user,
            };
        case 'REGISTER':
            return { ...state, user: { ...state.user, [action.payload.id]: action.payload } };
        default:
            return state;
    }
};

export default authReducer;
