import React, { useEffect, useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { closeSearchPanel } from '@/store/actions/search';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useApi} from "@/hooks/useApi";

const SearchForm = () => {
    const open = useSelector(state => state.search.openPanel);
    const dispatch = useDispatch();
    const [clubs, setClubs] = useState([]);
    const [clubslug, setClubslug] = useState('');
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    const booksport = useApi()

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleEsc = (event) => {
                if (event.key === 'Escape') {
                    closeSearch();
                }
            };

            if (open) {
                getClubs();
                window.addEventListener('keydown', handleEsc);
                document.body.classList.add('fixed'); // Prevent body scroll
            } else {
                window.removeEventListener('keydown', handleEsc);
                document.body.classList.remove('fixed'); // Re-enable body scroll
            }

            return () => {
                window.removeEventListener('keydown', handleEsc);
                document.body.classList.remove('fixed');
            };
        }
    }, [open]);

    useEffect(() => {
        if (open && inputRef.current) {
            inputRef.current.focus();
        }
    }, [open]);

    const getClubs = async () => {
        const clubsres = await booksport.get('/getclubs');
        const clubsArray = clubsres.data.map(club => ({
            label: `${club.name} (${club.short}) - ${club.sport}`,
            value: club.slug,
        }));
        setClubs(clubsArray);
    };

    const closeSearch = () => {
        setClubslug('');
        setInputValue('');
        dispatch(closeSearchPanel());
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (clubslug) {
            window.location.href = `/${clubslug}`;
        }
    };

    const handleInputChange = (_event, value) => {
        setInputValue(value);
    };

    const handleSuggestionSelect = (_event, suggestion) => {
        if (suggestion) {
            setClubslug(suggestion.value);
            setInputValue(suggestion.label);
            window.location.href = `/${suggestion.value}`;
        }
    };

    return (
        <Modal open={open} onClose={closeSearch} closeAfterTransition>
            <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        bgcolor: 'white',
                        padding: '20px 10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="container-fluid p-0">
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                bgcolor: 'transparent',
                                borderRadius: 1,
                                padding: { lg: '0 20px 0 5px' }
                            }}
                        >
                            <Button
                                href={clubslug ? `/${clubslug}` : ''}
                                sx={{
                                    padding: 0,
                                    height: '100%',
                                    minWidth: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <img src='/images/search-icon.svg' alt="Search" style={{ height: 25, width: 25 }} />
                            </Button>
                            <form onSubmit={handleFormSubmit} style={{ flexGrow: 1, display: 'flex', margin: 0 }}>
                                <Autocomplete
                                    freeSolo
                                    fullWidth
                                    options={clubs}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onChange={handleSuggestionSelect}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Caută club"
                                            variant="outlined"
                                            inputRef={inputRef}
                                            autoFocus
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <></>
                                                ),
                                                style: {
                                                    padding: '10px',
                                                    fontSize: '18px',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    border: 'none', // Remove the border
                                                },
                                            }}
                                            sx={{
                                                '& fieldset': {
                                                    border: 'none', // Remove the border
                                                },
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.value} style={{ cursor: 'pointer', fontFamily: 'Poppins, sans-serif' }}>
                                            {option.label}
                                        </li>
                                    )}
                                    ListboxProps={{
                                        style: {
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            fontFamily: 'Poppins, sans-serif', // Use Poppins font
                                        },
                                    }}
                                />
                            </form>
                            <Button
                                onClick={closeSearch}
                                sx={{
                                    color: 'black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: 0,
                                    height: '100%',
                                    minWidth: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <p className='d-none d-md-block' style={{ fontSize: 18, margin: 0 }}>ÎNCHIDE</p>
                                <CloseIcon size={18} />
                            </Button>
                        </Box>
                    </div>
                </Box>
            </Slide>
        </Modal>
    );
};

export default SearchForm;
