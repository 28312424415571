
const INITIAL_STATE = {
    openModal : false,
    commentHash : null,
    reviewID: null,
    refreshComments: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'OPEN_MODAL_DELETE_COMMENT':
            return {...state, openModal: action.payload.open, commentHash: action.payload.commentHash, reviewID: action.payload.reviewID}
        case 'CLOSE_MODAL_DELETE_COMMENT':
            return {...state, reviewID: null, openModal:false, commentHash:null,  refreshComments: false}
        case 'REFRESH_COMMENTS':
            return {...state, reviewID: action.payload.reviewID, refreshComments: !state.refreshComments}
        default :
            return state
    }
}
