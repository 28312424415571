import React from 'react';
import Image from 'next/image';

const ThreeStepsSection: React.FC = () => {
    const imageHeight: number = 150; // Define a common height for images
    const aspectRatio: number = 2; // Example aspect ratio (width / height)

    return (
        <div className="three-steps mx-auto mt-16 lg:mt-24 mb-16 lg:mb-24">
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row justify-between">
                    {/* Step 1 */}
                    <div className="step-wrapper text-center p-4">
                        <div className="relative mx-auto">
                            <Image
                                src="/images/search.png"
                                alt="search"
                                width={imageHeight * aspectRatio}
                                height={imageHeight}
                                style={{ objectFit: 'cover', width: 'auto', height: 'auto' }}
                            />
                        </div>
                        <p className="mt-4 text-gray-700 text-lg lg:text-xl">
                            Fă o rezervare la clubul favorit fără costuri suplimentare
                        </p>
                    </div>

                    {/* Step 2 */}
                    <div className="step-wrapper text-center p-4">
                        <div className="relative mx-auto">
                            <Image
                                src="/images/card.png"
                                alt="pay"
                                width={imageHeight * aspectRatio}
                                height={imageHeight}
                                style={{ objectFit: 'cover', width: 'auto', height: 'auto' }}
                            />
                        </div>
                        <p className="mt-4 text-gray-700 text-lg lg:text-xl">
                            Plătește rapid cu cardul 100% sigur
                        </p>
                    </div>

                    {/* Step 3 */}
                    <div className="step-wrapper text-center p-4">
                        <div className="relative mx-auto">
                            <Image
                                src="/images/play.png"
                                alt="play"
                                width={imageHeight * aspectRatio}
                                height={imageHeight}
                                style={{ objectFit: 'cover', width: 'auto', height: 'auto' }}
                            />
                        </div>
                        <p className="mt-4 text-gray-700 text-lg lg:text-xl">
                            Bucură-te de activitatea favorită împreună cu prietenii tăi
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeStepsSection;
