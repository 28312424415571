import React, { useState } from 'react';
// @ts-ignore
import Slider, { Settings } from 'react-slick';
import HomepageBookingCard from './HomepageBookingCard';
import Image from 'next/image';

// Ensure these images are placed in the 'public/images' directory
const images: string[] = [
    '/images/home-bg.jpg',
    '/images/massage.jpg',
    '/images/sanatate.jpg',
];

const textArray: string[] = ['Sportul', 'Relaxarea', 'Sănătatea'];

const BackgroundHome: React.FC = () => {
    const [index, setIndex] = useState<number>(0);

    const settings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'linear',
        pauseOnHover: false,
        beforeChange: (_oldIndex: number, newIndex: number) => {
            setIndex(newIndex);
        },
    };

    return (
        <div className="relative w-full h-full home-slider">
            <Slider {...settings} className="h-full overflow-hidden">
                {images.map((image, i) => (
                    <div key={i} className="h-full">
                        <div className="h-full bg-cover bg-center position-relative">
                            <Image
                                src={image}
                                alt={`Slide ${index + 1}`}
                                fill
                                style={{ objectFit: 'cover' }}
                                quality={80} // Slightly higher quality for better visuals
                                priority={index === 0}
                                className="z-0"
                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                            />
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
                <div className="text-white text-3xl md:text-4xl lg:text-5xl font-bold mb-2 md:mb-4 text-center px-4">
                    <span>{`${textArray[index]} mai aproape`}</span>
                </div>
                <div className="mb-4 md:mb-8 w-full flex justify-center">
                    <div className="container mx-auto">
                        <HomepageBookingCard />
                    </div>
                </div>
                <div className="flex space-x-4 z-20">
                    <a
                        href="https://apps.apple.com/us/app/booksport/id1585479595"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Image
                            className="w-36 h-auto"
                            src="/images/appstore.png"
                            alt="App Store"
                            width={150}
                            height={50}
                            priority
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                        />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=ro.Booksport.Booksport"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Image
                            className="w-36 h-auto"
                            src="/images/googleplay.png"
                            alt="Google Play"
                            width={150}
                            height={50}
                            priority
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BackgroundHome;
