// src/context/AuthContext.tsx
import React, {createContext, useContext, useState, ReactNode, useEffect} from 'react';
import axios from 'axios';

type AuthContextType = {
    token: string | null;
    setToken: (newToken: string | null) => void;
    getOrFetchToken: () => Promise<string | null>;
    login: (args: { email?: string; password?: string; token?: string }) => Promise<{ user?: string; token?: string; error?: string }>;
    logout: () => Promise<void>;
    authInitialized: boolean;
};



const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [token, setTokenState] = useState<string | null>(null);
    const [authInitialized, setAuthInitialized] = useState(false);

    /**
     * setToken: updates local state
     */
    function setToken(newToken: string | null) {
        setTokenState(newToken);
    }

    /**
     * getOrFetchToken:
     *  - if we have a token in memory, return it
     *  - else, call /api/get-token to read from the cookie, set it, and return
     */
    async function getOrFetchToken(): Promise<string | null> {
        if (token) {
            return token;
        }
        try {
            const resp = await axios.get('/api/get-token', { withCredentials: true });
            const { token: fetchedToken } = resp.data;
            if (fetchedToken) {
                setToken(fetchedToken);
                return fetchedToken;
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    }

    /**
     * login: calls /api/login
     * on success, sets token + returns { user, token }
     */
    async function login({
                             email,
                             password,
                             token,
                         }: {
        email?: string;
        password?: string;
        token?: string;
    }): Promise<{ user?: string; token?: string; error?: string }> {
        try {
            const endpoint = token ? '/api/set-session' : '/api/login';
            const payload = token ? { token } : { email, password };
            const resp = await axios.post(endpoint, payload);
            const { user, token: receivedToken } = resp.data;

            if (receivedToken) {
                setToken(receivedToken);
            }

            return { user, token: receivedToken, error: undefined };
        } catch (err: any) {
            const errorMessage = err.response?.data?.error || 'An unexpected error occurred';
            return { user: undefined, token: undefined, error: errorMessage };
        }
    }


    /**
     * logout: calls /api/logout, clears token, and redirects
     */
    async function logout() {
        try {
            await axios.post('/api/logout');
        } catch (err) {
            console.error('[AuthContext] logout error:', err);
        } finally {
            setToken(null);
            localStorage.removeItem('jwtToken')
            localStorage.removeItem('user')
        }
    }

    useEffect(() => {
        (async () => {
            await getOrFetchToken();
            setAuthInitialized(true);
        })();
    }, [token]);

    const value: AuthContextType = {
        token,
        setToken,
        getOrFetchToken,
        login,
        logout,
        authInitialized
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextType {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
