import React from 'react';
import { Provider } from 'react-redux';
import { wrapper } from '@/store';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/main-dashboard.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type {AppPropsWithLayout} from "@/layouts/types/nextPageLayout";
import {AuthProvider} from "@/context/AuthContext";

const MyApp = ({ Component, pageProps, ...rest }: AppPropsWithLayout) => {
    const { store } = wrapper.useWrappedStore(rest);
    const getLayout = Component.getLayout ?? ((page:any) => page);

    return (
        <AuthProvider>
            <Provider store={store}>
                {getLayout(<Component {...pageProps} />)}
                <ToastContainer
                    position="top-right"
                    autoClose={5000} // Automatically close after 5 seconds
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Provider>
        </AuthProvider>

    );
};

export default wrapper.withRedux(MyApp);
