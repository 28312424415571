import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import BackgroundHome from './homepage/components/BackgroundHome';
import PromoClubsCrousel from "@/pages/homepage/components/PromoClubsCrousel";
import ThreeStepsSection from "@/pages/homepage/components/ThreeStepsSection";
import MobileAppSection from "@/pages/homepage/components/MobileAppSection";
import Footer from "@/components/reusable/Footer";
import BaseLayout from "@/layouts/base-layout";
import { GetServerSideProps } from 'next';
import booksport from '@/api-routes/booksport';

interface HomepageProps {
    clubs: Club[];
    error?: string;
}

export interface Club {
    id: string | number;
    slug: string;
    main_card_message?: string;
    main_card_message_bkg_color?: string;
    main_card_message_color?: string;
    filename?: string;
    name: string;
    locationname: string;
    sport: string;
    booking: number;
    review: number;
    nr_review: number;
    address: string;
    elements: string;
    localitate: string;
}

export interface ApiResponseData {
    clubs: Club[];
}

const Homepage: React.FC<HomepageProps> = ({ clubs, error }) => {
    const Map = useMemo(() => dynamic(
        () => import('@/components/maps/MapSection'),
        {
            ssr: false
        }
    ), []);

    return (
        <BaseLayout navbarProps={{ logoW: true, showFilters: false }}>
            <div className="page-header home-panel relative h-[70vh]">
                <BackgroundHome />
            </div>
            <PromoClubsCrousel clubs={clubs} error={error} />
            <Map/>
            <ThreeStepsSection/>
            <MobileAppSection/>
            <Footer/>
        </BaseLayout>
    );
};

export const getServerSideProps: GetServerSideProps<HomepageProps> = async (context) => {
    try {
        const res = await booksport.get<ApiResponseData>('/listpromoclubs');
        const data = res.data;

        return {
            props: {
                clubs: data.clubs || [],
            },
        };
    } catch (error: any) {
        console.error('Error fetching clubs:', error);

        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch clubs';

        return {
            props: {
                clubs: [],
                error: errorMessage,
            },
        };
    }

}

export default Homepage;
