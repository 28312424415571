import React from 'react';
import Image from 'next/image';
import { Typography, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';

const MobileAppSection: React.FC = () => {
    return (
        <div className="app-section mx-auto mt-12 lg:mt-24 mb-12 lg:mb-24">
            <div className="container mx-auto px-4">
                <div className="flex flex-col lg:flex-row items-center">
                    {/* Image Section */}
                    <div className="w-full lg:w-1/2 flex justify-center mb-8 lg:mb-0">
                        <Image
                            src="/images/phone.png"
                            alt="phone"
                            style={{ maxWidth: '100vw' }}
                            width={500}
                            height={600}
                            className="mx-auto"
                        />
                    </div>

                    {/* Description Section */}
                    <div className="w-full lg:w-1/2">
                        <div className="app-description">
                            <Typography
                                variant="h3"
                                sx={{
                                    color: '#12803c',
                                    fontWeight: 500,
                                    fontSize: { xs: '22px', md: '34px' },
                                    margin: '20px 0',
                                    fontFamily: 'Poppins, sans-serif',
                                }}
                            >
                                Descarcă aplicația
                            </Typography>

                            <div className="reviews-app mb-4">
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                <p className="italic">"Cea mai bună aplicație de acest gen din țară!"</p>
                                <Rating
                                    value={5}
                                    readOnly
                                    precision={0.5}
                                    size="large"
                                    icon={<StarIcon style={{ fontSize: '25px', color: '#faaf00' }} />}
                                    emptyIcon={<StarBorder style={{ fontSize: '25px', color: '#faaf00' }} />}
                                />
                            </div>

                            <ul className="list-disc list-inside text-left mb-6">
                                <li>Geolocalizare și afișarea cluburilor pe hartă</li>
                                <li>Lista cluburilor disponibile în apropiere</li>
                                <li>Rezervări în mai puțin de 5 minute</li>
                                <li>Plată rapidă și 100% sigură</li>
                                <li>Istoric de rezervări</li>
                            </ul>

                            <div className="flex justify-start space-x-4">
                                <a href="https://apps.apple.com/us/app/booksport/id1585479595" target="_blank" rel="noopener noreferrer">
                                    <Image src="/images/appstore.png" alt="App Store" width={150} height={50} />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=ro.Booksport.Booksport" target="_blank" rel="noopener noreferrer">
                                    <Image src="/images/googleplay.png" alt="Google Play" width={150} height={50} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileAppSection;
