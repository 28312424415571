// Define the modal voucher state interface
interface ModalVoucherState {
    show: boolean;
    encrypted: string;
    amount: number;
}

// Define the state interface
interface TransactionsState {
    modal: boolean;
    modalVoucher: ModalVoucherState | false;  // Either an object or false
    refresh: boolean;
}

// Define initial state
const initialState: TransactionsState = {
    modal: false,
    modalVoucher: false,
    refresh: false,
};

// Define Action Types as constants or use an enum
const SHOW_MODAL = 'SHOW_MODAL';
const SHOW_MODAL_VOUCHER = 'SHOW_MODAL_VOUCHER';
const REFRESH_TRANSACTIONS = 'REFRESH_TRANSACTIONS';

// Define Action Interfaces
interface ShowModalAction {
    type: typeof SHOW_MODAL;
    payload: boolean;
}

interface ShowModalVoucherAction {
    type: typeof SHOW_MODAL_VOUCHER;
    payload: ModalVoucherState | false;  // The payload can be an object or false
}

interface RefreshTransactionsAction {
    type: typeof REFRESH_TRANSACTIONS;
    payload: boolean;
}

// Create a type that represents all possible actions
type TransactionsActionTypes = ShowModalAction | ShowModalVoucherAction | RefreshTransactionsAction;

// Define the reducer function with the specific action types
const transactionsReducer = (
    state: TransactionsState = initialState,
    action: TransactionsActionTypes
): TransactionsState => {
    switch (action.type) {
        case SHOW_MODAL:
            return { ...state, modal: action.payload };
        case SHOW_MODAL_VOUCHER:
            return { ...state, modalVoucher: action.payload };  // Can handle both an object or false
        case REFRESH_TRANSACTIONS:
            return { ...state, refresh: action.payload };
        default:
            return state;
    }
};

export default transactionsReducer;
