interface AdminState {
    modalDeleteCard: boolean;
    refreshCards: boolean;
    paymentCard: any; // You can be more specific if you know the structure of `card`
}

const initialState: AdminState = {
    modalDeleteCard: false,
    refreshCards: false,
    paymentCard: null,
};

interface Action {
    type: string;
    show?: boolean;
    refresh?: boolean;
    card?: any; // Replace `any` with the actual type if available
}

export default (state = initialState, action: Action): AdminState => {
    switch (action.type) {
        case 'SHOW_MODAL_DELETE_CARD':
            return { ...state, modalDeleteCard: action.show ?? false };
        case 'REFRESH_CARDS_LIST':
            return { ...state, refreshCards: action.refresh ?? false };
        case 'SET_PAYMENT_CARD':
            return { ...state, paymentCard: action.card ?? null };
        default:
            return state;
    }
};
