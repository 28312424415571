import { combineReducers } from 'redux';
import modalReducer from './modalReducer';
import userReducer from './userReducer';
import refresh from './refreshReducer';
import auth from './auth';
import refreshDay from './refreshDayReducer';
import filterClubs from './clubsFilterReducer';
import reviews from './reviews';
import search from './search';
import rezervare from "./rezervareReducer";
import loader from './loader';
import admin from './admin';

const rootReducer = combineReducers({
    showModal: modalReducer,
    userStatus: userReducer,
    userInfo: auth,
    refresh: refresh,
    refreshDay: refreshDay,
    filterClubs: filterClubs,
    reviews,
    search,
    rezervare,
    loader,
    admin,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
