// Define action types as an enum for better maintainability
enum ModalActionTypes {
    OPEN_MODAL = 'OPEN_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
    OPEN_MODAL_REZERVA = 'OPEN_MODAL_REZERVA',
    CLOSE_MODAL_REZERVA = 'CLOSE_MODAL_REZERVA',
    OPEN_MODAL_REZERVA_TABLE = 'OPEN_MODAL_REZERVA_TABLE',
    CLOSE_MODAL_REZERVA_TABLE = 'CLOSE_MODAL_REZERVA_TABLE',
    OPEN_MODAL_VOUCHER = 'OPEN_MODAL_VOUCHER',
    SHOW_MODAL_DELETE_ACCOUNT = 'SHOW_MODAL_DELETE_ACCOUNT',
}

// Define the ModalState interface
interface ModalState {
    showModal?: boolean;
    rezerva?: boolean;
    modalVoucher?: boolean;
    modalDeleteAccount?: boolean;
}

// Define specific action interfaces using discriminated unions
interface OpenModalAction {
    type: ModalActionTypes.OPEN_MODAL | ModalActionTypes.CLOSE_MODAL;
    showModal: boolean;
}

interface RezervaModalAction {
    type:
        | ModalActionTypes.OPEN_MODAL_REZERVA
        | ModalActionTypes.CLOSE_MODAL_REZERVA
        | ModalActionTypes.OPEN_MODAL_REZERVA_TABLE
        | ModalActionTypes.CLOSE_MODAL_REZERVA_TABLE;
    rezerva: boolean;
}

interface OpenVoucherModalAction {
    type: ModalActionTypes.OPEN_MODAL_VOUCHER;
    open: boolean;
}

interface DeleteAccountModalAction {
    type: ModalActionTypes.SHOW_MODAL_DELETE_ACCOUNT;
    open: boolean;
}

// Union of all possible actions
type ModalAction =
    | OpenModalAction
    | RezervaModalAction
    | OpenVoucherModalAction
    | DeleteAccountModalAction;

// Initial state
const initialState: ModalState = {};

// Modal reducer
const modalReducer = (state: ModalState = initialState, action: ModalAction): ModalState => {
    switch (action.type) {
        case ModalActionTypes.OPEN_MODAL:
        case ModalActionTypes.CLOSE_MODAL:
            return { ...state, showModal: action.showModal };

        case ModalActionTypes.OPEN_MODAL_REZERVA:
        case ModalActionTypes.CLOSE_MODAL_REZERVA:
        case ModalActionTypes.OPEN_MODAL_REZERVA_TABLE:
        case ModalActionTypes.CLOSE_MODAL_REZERVA_TABLE:
            return { ...state, rezerva: action.rezerva };

        case ModalActionTypes.OPEN_MODAL_VOUCHER:
            return { ...state, modalVoucher: action.open };

        case ModalActionTypes.SHOW_MODAL_DELETE_ACCOUNT:
            return { ...state, modalDeleteAccount: action.open };

        default:
            return state;
    }
};

export default modalReducer;
