// Define the shape of the state
interface DocumentsState {
    refresh: boolean;
}

// Define the possible action types
interface RefreshDocumentsAction {
    type: 'REFRESH_DOCUMENTS';
    refresh: boolean;
}

// Define a type for all possible actions (you can add more as needed)
type DocumentsAction = RefreshDocumentsAction;

// Define the initial state with a default structure
const initialState: DocumentsState = {
    refresh: false,
};

// The reducer function with proper typing
const documentsReducer = (state: DocumentsState = initialState, action: DocumentsAction): DocumentsState => {
    switch (action.type) {
        case 'REFRESH_DOCUMENTS':
            return { ...state, refresh: action.refresh };
        default:
            return state;
    }
};

export default documentsReducer;
