// Define the state interface
interface VoucherState {
    items: any[]; // Adjust type if known
    modal: boolean;
    modalUserVoucher: boolean;
    refresh: boolean;
}

// Define the initial state based on the interface
const initialState: VoucherState = {
    items: [],
    modal: false,
    modalUserVoucher: false,
    refresh: false,
};

// Update the reducer to use the correct initial state and handle actions
export default (state: VoucherState = initialState, action: any): VoucherState => {
    switch (action.type) {
        case 'ADD_ITEMS':
            return { ...state, items: action.items };
        case 'SHOW_MODAL':
            return { ...state, modal: action.show };
        case 'SHOW_USER_MODAL':
            return { ...state, modalUserVoucher: action.show };
        case 'REFRESH_VOUCHERS':
            return { ...state, refresh: action.refresh };
        default:
            return state;
    }
};
