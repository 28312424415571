'use client'
import React, { useState, useEffect, useCallback, FormEvent } from 'react';
import {
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Box,
    Button,
} from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ro';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useRouter } from 'next/router';
import { useApi } from '@/hooks/useApi';

// Extend dayjs with custom parse format
dayjs.extend(customParseFormat);
dayjs.locale('ro'); // Set dayjs to use the Romanian locale

// Define interfaces for data structures
interface Option {
    label: string;
    value: string | number;
}

// Styled components
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '50px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '50px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '50px',
        display: 'flex',
        alignItems: 'center',
    },
});

const HomepageBookingCard: React.FC = () => {
    const [startDate, setStartDate] = useState<Dayjs>(
        dayjs().hour() === 23 ? dayjs().add(1, 'day') : dayjs()
    );
    const [location, setLocation] = useState<Option>({ label: 'București', value: 9000 });
    const [hours, setHours] = useState<Option[]>([]);
    const [selectedHour, setSelectedHour] = useState<Option | null>(null);
    const [sports, setSports] = useState<Option[]>([]);
    const [selectedSport, setSelectedSport] = useState<Option | null>(null);
    const [cities, setCities] = useState<Option[]>([]);
    const booksport = useApi();
    const router = useRouter();

    useEffect(() => {
        const fetchInitialData = async () => {
            if (typeof navigator !== 'undefined') {
                try {
                    let currentLocation: Option | null = null;

                    if (navigator.permissions) {
                        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

                        if (permissionStatus.state === 'granted') {
                            currentLocation = await new Promise((resolve) => {
                                navigator.geolocation.getCurrentPosition(
                                    (position) => {
                                        resolve({
                                            label: 'LOCAȚIA CURENTĂ',
                                            value: `@${position.coords.latitude},${position.coords.longitude}`,
                                        });
                                    },
                                    () => {
                                        resolve(null);
                                    }
                                );
                            });
                        } else if (permissionStatus.state === 'prompt') {
                            currentLocation = { label: 'LOCAȚIA CURENTĂ', value: 0 };
                        }
                    }

                    const res = await booksport.get('/get_cities');
                    const ApiCities = res.data.map((city: any) => ({
                        label: city.name,
                        value: city.id,
                    }));

                    if (currentLocation) {
                        ApiCities.splice(1, 0, currentLocation);
                    }

                    setCities(ApiCities);
                } catch (err) {
                    console.error('Error fetching data:', err);
                }
            }
        };

        fetchInitialData().catch();
        updateHours(startDate);
    }, []);


    useEffect(() => {
        let permissionStatus: PermissionStatus | null = null;

        const handlePermissionChange = async () => {
            try {
                permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

                const removeCurrentLocation = () => {
                    setCities((prevCities) => {
                        const updatedCities = prevCities.filter((city) => city.label !== 'LOCAȚIA CURENTĂ');
                        if (updatedCities.length > 0) {
                            setLocation(updatedCities[0]); // Set location to the first option
                        }
                        return updatedCities;
                    });
                };

                if (permissionStatus.state === 'granted') {
                    fetchCurrentLocation().catch((err) =>
                        console.error('Error fetching current location:', err)
                    );
                } else if (permissionStatus.state === 'denied') {
                    removeCurrentLocation();
                }

                permissionStatus.onchange = () => {
                    if (permissionStatus?.state === 'granted') {
                        fetchCurrentLocation().catch((err) =>
                            console.error('Error fetching current location:', err)
                        );
                    } else if (permissionStatus?.state === 'denied') {
                        removeCurrentLocation();
                    }
                };
            } catch (err) {
                console.error('Error handling permissions:', err);
            }
        };

        handlePermissionChange().catch((err) =>
            console.error('Error initializing permission handling:', err)
        );

        return () => {
            if (permissionStatus) {
                permissionStatus.onchange = null;
            }
        };
    }, []);



    const fetchCurrentLocation = async () => {
        if (typeof navigator !== 'undefined' && 'geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentLocation = {
                        label: 'LOCAȚIA CURENTĂ',
                        value: `@${position.coords.latitude},${position.coords.longitude}`,
                    };
                    setCities((prevCities) => {
                        const updatedCities = [...prevCities];
                        if (!updatedCities.some((city) => city.label === 'LOCAȚIA CURENTĂ')) {
                            updatedCities.splice(1, 0, currentLocation);
                        }
                        return updatedCities;
                    });
                },
                (error) => {
                    console.error('Error fetching current location:', error);
                }
            );
        }
    };

    useEffect(() => {
        if (location) {
            fetchSports(location.value).catch();
        }
    }, [location]);


    const fetchSports = async (locationId: string | number) => {
        try {
            const res = await booksport.get('/listmenusports', {
                params: { location_id: locationId, radius: 20 },
            });
            const ApiSports = res.data.map((sport: any) => ({
                label: `${sport.name} (${sport.cnt})`,
                value: sport.id,
            }));
            setSports(ApiSports);
            if (res.data.length > 0) {
                setSelectedSport({ label: res.data[0].name, value: res.data[0].id });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const generateHours = (selectedDate: Dayjs): Option[] => {
        const now = dayjs();
        const isToday = dayjs(selectedDate).isSame(now, 'day');
        const hoursList: Option[] = [];

        for (let i = 0; i < 24; i++) {
            const hourStr = i.toString().padStart(2, '0') + ':00';
            const halfHourStr = `${hourStr.slice(0, 2)}:30`;

            if (isToday) {
                if (dayjs(hourStr, 'HH:mm').isAfter(now)) {
                    hoursList.push({ label: hourStr, value: hourStr });
                }
                if (dayjs(halfHourStr, 'HH:mm').isAfter(now)) {
                    hoursList.push({ label: halfHourStr, value: halfHourStr });
                }
            } else {
                if (i >= 7 && i < 24) {
                    hoursList.push({ label: hourStr, value: hourStr });
                    hoursList.push({ label: halfHourStr, value: halfHourStr });
                }
            }
        }
        return hoursList;
    };

    const updateHours = useCallback((selectedDate: Dayjs) => {
        const hoursList = generateHours(selectedDate);
        setHours(hoursList);
        setSelectedHour(hoursList[0] || null);
    }, []);

    const handleDateChange = (date: Dayjs | null) => {
        if (date) {
            setStartDate(date);
            updateHours(date);
        }
    };

    const handleLocationChange = async (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value as string | number;

        if (value === 0) {
            try {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        if (position.coords.latitude && position.coords.longitude) {
                            const updatedCities = [...cities];
                            updatedCities[1] = {
                                label: 'LOCAȚIA CURENTĂ',
                                value: `@${position.coords.latitude},${position.coords.longitude}`,
                            };
                            setCities(updatedCities);
                            setLocation(updatedCities[1]);
                        }
                    },
                    async (error) => {
                        if (error.code === error.PERMISSION_DENIED) {
                            const permissionStatus = await navigator.permissions.query({
                                name: 'geolocation',
                            });

                            if (permissionStatus.state === 'denied') {
                                const updatedCities = cities.filter((city) => city.value !== 0);
                                setCities(updatedCities);
                            }
                        }
                    }
                );
            } catch (err) {
                console.error("Error handling location change:", err);
            }
        } else {
            // For other cities, update the selected location
            const selected = cities.find((option) => option.value === value);
            if (selected) {
                setLocation(selected);
            }
        }
    };





    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const locationValue = location.value;
        const sport = selectedSport ? selectedSport.value : null;
        const hour = selectedHour ? selectedHour.value : null;
        const date = startDate.format('YYYY-MM-DD');
        const radius = typeof locationValue === 'string' && locationValue.startsWith('@') ? 20 : null;

        router.push(
            `/clubs?date=${date}&hour=${hour}&location=${locationValue}${
                radius ? `&radius=${radius}` : ''
            }&sport=${sport}`
        );
    };


    return (
        <Box className="ml-auto mr-auto booking-card-homepage">
            <Box className="card">
                <Box className="card-body">
                    <form onSubmit={handleSubmit}>
                        <Box className="row align-items-center">
                            <Box className="col-lg-3 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <GreenSelect
                                        value={location.value}
                                        onChange={handleLocationChange}
                                        displayEmpty
                                        sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            classes: {
                                                paper: 'scrollable-menu',
                                            },
                                        }}
                                    >
                                        {cities.map((option) => (
                                            <MenuItem key={option.value} value={option.value} sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </GreenSelect>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-3 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <GreenSelect
                                        value={selectedSport ? selectedSport.value : ''}
                                        onChange={(e) =>
                                            setSelectedSport(
                                                sports.find((s) => s.value === e.target.value) || null
                                            )
                                        }
                                        displayEmpty
                                        sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            classes: {
                                                paper: 'scrollable-menu',
                                            },
                                        }}
                                    >
                                        {sports.map((option) => (
                                            <MenuItem key={option.value} value={option.value} sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </GreenSelect>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-2 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ro">
                                        <DatePicker
                                            value={startDate}
                                            onChange={handleDateChange}
                                            minDate={dayjs()}
                                            maxDate={dayjs().add(14, 'day')}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    sx: {
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: "green"
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "green"
                                                        }
                                                    }
                                                }
                                            }}
                                            format="DD.MM.YYYY"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-2 mb-2 mb-lg-0">
                                <FormControl fullWidth>
                                    <GreenSelect
                                        value={selectedHour ? selectedHour.value : ''}
                                        onChange={(e) =>
                                            setSelectedHour(
                                                hours.find((h) => h.value === e.target.value) || null
                                            )
                                        }
                                        displayEmpty
                                        sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            classes: {
                                                paper: 'scrollable-menu',
                                            },
                                        }}
                                    >
                                        {hours.map((option) => (
                                            <MenuItem key={option.value} value={option.value} sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </GreenSelect>
                                </FormControl>
                            </Box>
                            <Box className="col-lg-2 d-flex align-items-center">
                                <Button type="submit" variant="contained" color="success" fullWidth style={{ height: '50px' }}>
                                    Caută
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default HomepageBookingCard;
