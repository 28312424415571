import React, { ReactNode } from 'react'
import Head from "next/head";
import Navbar from "@/components/reusable/Navbar";

interface LayoutProps {
    children: ReactNode;
    navbarProps?: {
        logoW?: boolean;
        showFilters?: boolean;
        className?: string
    };
}

const BaseLayout = ({ children, navbarProps = { logoW: true, showFilters: false, className: '' } }: LayoutProps) => {
    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <link rel="icon" href="/favicon.ico" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                {/* Web Manifest */}
                <link rel="manifest" href="/site.webmanifest" />
                <title>Booksport.ro | Rezervă online</title>
                <link rel="canonical" href={typeof window !== 'undefined' ? window.location.href : ''} />
                <meta name="keywords" content="Booksport, tenis, inchiriere, masaj, echitatie, fotbal, online, ping-pong, plata, card,teren" />
                <meta name="description" content="Booksport este o platforma online pentru inchieri de terenuri de tenis, terenuri de fotbal, saloane de masaj, mese de ping-pong si multe altele ce permite plata online cu cardul" />
                <meta property="og:site_name" content="booksport.ro" />
                <meta property="og:title" content="Booksport" />
                <meta property="og:description" content="Booksport este o platforma online pentru inchieri de terenuri de tenis, terenuri de fotbal, saloane de masaj, mese de ping-pong si multe altele ce permite plata online cu cardul" />
            </Head>
            <Navbar {...navbarProps} />
            {children}
        </>
    )
}

export default BaseLayout;
