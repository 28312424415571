import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Combined State Interface
interface BookingsState {
    refreshBookings: boolean;
    loadingCount: number;
}

// Initial State
const initialState: BookingsState = {
    refreshBookings: false,
    loadingCount: 0,
};

// Create Slice
const bookingsSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        refreshBookings: (state, action: PayloadAction<boolean>) => {
            state.refreshBookings = action.payload;
        },
        startLoading: (state) => {
            state.loadingCount += 1;
        },
        finishLoading: (state) => {
            state.loadingCount = Math.max(0, state.loadingCount - 1);
        },
    },
});

export const { refreshBookings, startLoading, finishLoading } = bookingsSlice.actions;
export default bookingsSlice.reducer;
