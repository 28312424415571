import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import { PulseLoader } from 'react-spinners';
import queryString from 'query-string';
import {useApi} from "@/hooks/useApi";

interface SportOption {
    label: string;
    value: string;
}

// Styled component to change the focus color to green and adjust height
const GreenSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
    },
    '.MuiOutlinedInput-input': {
        height: '40px',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiSelect-select': {
        height: '40px!important',
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
    },
    '.MuiOutlinedInput-root': {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
});

const SportFilter: React.FC = () => {
    const [sportsOptions, setSportsOptions] = useState<SportOption[]>([]);
    const [sportValue, setSportValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const router = useRouter();
    const { pathname } = router;
    const queryParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
    const booksport = useApi()

    useEffect(() => {
        const locationValue = queryParams.location as string;
        const radius = queryParams.radius as string;

        if (locationValue) {
            setLoading(true)
            const fetchSports = async () => {
                try {
                    const res = await booksport.get('/listmenusports', {
                        params: { location_id: locationValue, radius: radius },
                    });
                    const sports = res.data.map((sport: { name: string; cnt: number; id: string }) => ({
                        label: `${sport.name} (${sport.cnt})`,
                        value: sport.id,
                    }));
                    setSportsOptions(sports);

                    // Set initial sport value from query params or first available option
                    const sportValueFromQuery = queryParams.sport as string;
                    if (sportValueFromQuery) {
                        setSportValue(sportValueFromQuery);
                    } else if (sports.length > 0) {
                        const defaultSport = sports[0].value;
                        setSportValue(defaultSport);
                        queryParams.sport = defaultSport;
                        router.replace(`${pathname}?${queryString.stringify(queryParams)}`, undefined, { shallow: true });
                    }

                    setLoading(false);
                } catch (err: any) {
                    console.error(err.response.data);
                    setLoading(false);
                }
            };

            fetchSports();
        }
    }, [queryParams.location, queryParams.radius]);

    const handleSportChange = (event: any) => {
        const newSport = event.target.value;
        const currentParams = typeof window !== 'undefined' ? queryString.parse(location.search) : {};
        const newParams = { ...currentParams, sport: newSport };
        const newQueryString = queryString.stringify(newParams);

        router.replace(`${pathname}?${newQueryString}`, undefined, { shallow: true });
        setSportValue(newSport);
    };

    return (
        <FormControl fullWidth>
            <GreenSelect
                value={sportValue}
                onChange={handleSportChange}
                displayEmpty
                sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 400,
                        },
                    },
                    PopoverClasses: {
                        paper: 'scrollable-menu',
                    },
                    disableScrollLock: true,
                }}
            >
                {loading ? (
                        <MenuItem disabled value="" sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                            Se încarcă sporturile...
                        </MenuItem>
                    ) : (sportsOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ fontFamily: 'Poppins, Arial, sans-serif' }}>
                        {option.label}
                    </MenuItem>))
                )}
            </GreenSelect>
        </FormControl>
    );
};

export default SportFilter;
